
import ModalLogSugestaoPedidos from './components/ModalLogSugestaoPedidos.vue';
import { ref, onMounted, reactive, watch } from 'vue';
import useAlert from "@/composables/Alert";
import { ContentLoader } from "vue-content-loader";
import { useRouter } from "vue-router";
import useEmitter from '@/composables/Emmiter';
import FilterBase from '@/layout/header/partials/filters/FilterBase.vue';
import { getListaPedidos } from '@/services/SugestaoPedidos';
import { PedidoModel } from '@/models/SugestaoPedidoModel';

export default {
  components: {
    FilterBase,
    ModalLogSugestaoPedidos,
    ContentLoader
  },
  props: {
    filters: Object
  },
  setup() {
    const animate = ref(true)
    const bloquearEdicao = ref(true);
    const router = useRouter();
    const emitter = useEmitter();
    const { showTimeAlert } = useAlert();
    const codConcessionaria = window.localStorage.getItem("filtroConcessionaria") || "";
    const showModal = ref(false);
    const modalPedido = ref(null);
    const sugestaoEspecifica = ref<PedidoModel>();
    const pedidos = ref<PedidoModel[]>([]);

    function setSugestaoEspecifica(pedido: PedidoModel) {
      sugestaoEspecifica.value = pedido;
    }

    onMounted(async () => {
      animate.value = true;
      await getPedidos(codConcessionaria);
      animate.value = false;
    });

    const delayTimer = ref();

    function filterData() {
      clearTimeout(delayTimer.value);
      delayTimer.value = setTimeout(function () {
        pagination(1, true);
      }, 300);
    }

    const data = reactive({
      nome: "",
      hash_pedido: "",
      dataHora: "",
      total: 0,
      current_page: 1,
      last_page: 0,
      to: 0,
      per_page: 20,
      data: [] as PedidoModel[]
    });

    const fastFilter: any = ref("");

    function pagination(page = 1, filter = false) {
      let baseDeSugestoes: PedidoModel[] = [];

      if (filter) {
        baseDeSugestoes = pedidos.value.filter((pedido: PedidoModel) =>
          pedido.nome.toLowerCase().includes(fastFilter.value.toLowerCase()) ||
          pedido.data.toLowerCase().includes(fastFilter.value) ||
          pedido.hash_pedido.toLowerCase().includes(fastFilter.value)

        );
      } else {
        baseDeSugestoes = pedidos.value;
      }

      data.total = baseDeSugestoes.length;
      data.last_page = Math.ceil(baseDeSugestoes.length / data.per_page) ?? 0;
      data.current_page = page;

      const start = data.per_page * (page - 1);
      const end = data.per_page * page;

      if (data.last_page == page) {
        data.to = baseDeSugestoes.length;
      } else if (end > baseDeSugestoes.length) {
        data.to = baseDeSugestoes.length;
      } else {
        data.to = end;
      }

      data.data = baseDeSugestoes.slice(start, end);
    }

    async function getPedidos(codConcessionaria) {
      pedidos.value = [];
      try {
        const response = await getListaPedidos(codConcessionaria);
        pedidos.value = response;
        pagination(1);

      } catch (error) {
        console.error("Erro ao obter pedidos:", error);
      }
    };

    function goTo(url) {
      animate.value = true;
      router.push({
        name: 'estoque',
        params: {
          title: 'adicionar'
        }
      })
    }

    function editarPedido(pedido, index) {
      animate.value = true;
      if (index !== 0) {
        bloquearEdicao.value = true;
      } else {

        router.push(
          {
            name: 'estoque',
            params: {
              title: 'editar',
              codSugestaoPedido: pedido.codSugestaoPedido,
              codconcessionaria: pedido.codConcessionaria,
              hash_pedido: pedido.hash_pedido,
              byPush: "true",
              nomeConcessionaria: pedido.nomeConcessionaria
            }
          });
      }
    }

    emitter.on('filtrar-consultores-pedidos', async (params) => {
      await getPedidos(params.codConcessionaria);
    });

    return {
      pedidos,
      bloquearEdicao,
      editarPedido,
      goTo,
      showModal,
      modalPedido,
      setSugestaoEspecifica,
      sugestaoEspecifica,
      filterData,
      data,
      pagination,
      fastFilter,
      animate
    }
  },
}
