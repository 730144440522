
import { onMounted, onBeforeUnmount } from "vue";
import {ContentLoader} from 'vue-content-loader';
import { Modal } from "bootstrap";
import { ref, watch } from 'vue';
import { getlogSugestaoPedidos } from '@/services/SugestaoPedidos';
import { hideModal } from '@/core/helpers/dom';

export default {
    name: "modalLogSugestaoPedidos",

    props: {
        pedido :{
            type: Object,
            required: true
        }
    },

    components: {
        ContentLoader
    },

    setup(props) {
        const animate = ref(true);
        const sugestaoModal:any = ref();
        const hashPedido_header = ref('');

        watch(() => props.pedido, (newValue) => {
          if(newValue){
            listaView(newValue?.codSugestaoPedido);
            hashPedido_header.value = newValue?.hash_pedido;
          }
        })

        async function listaView(codSugestaoPedido) {
            animate.value = true;
            let response = await getlogSugestaoPedidos(codSugestaoPedido);
            sugestaoModal.value = response;
            animate.value = false;
        }

        let modalInstance: Modal | null = null;
        
    function closeModal(){
			const refModal = document.getElementById("modalLogSugestaoPedidos");
			hideModal(refModal);
		}

        return {
            modalInstance,
            listaView,
            animate,
            getlogSugestaoPedidos,
            closeModal,
            sugestaoModal,
            hashPedido_header
        };
    }
}
